/*!
 * proLibro (http://prolibro.com/)
 * Copyright CogniLore Inc. 2012-2018. All Rights Reserved.  
 * Any reuse or reproduction of the contents, in whole or in part, is expressly prohibited.
 */

@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,400,700");

// 2. Include any default variable overrides here

// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)
@import "./../../../node_modules/bootstrap/scss/functions";
@import "./../../../node_modules/bootstrap-vue/node_modules/bootstrap/scss/functions";
@import "./../../../node_modules/bootstrap-vue/node_modules/bootstrap/scss/variables";
@import "variables";
@import "branding";
@import "./../../../node_modules/bootstrap/scss/bootstrap";
@import "./../../../node_modules/bootstrap-vue/node_modules/bootstrap/scss/bootstrap.scss";

// Then add additional custom code here

@import "mixins";
@import "document";
@import "layout";
@import "navigation";
@import "icons";
@import "controls";
@import "views";
@import "context_menu";
@import "purchasing";
@import "community";
@import "datatable";
@import "professor";
@import "storefront";
